/* eslint-disable max-len */
import axios from 'axios'
import React, { ReactElement, useState } from 'react'
import IpHeader from '../components/IpHeader'
import IpInfo from '../components/IpInfo'
import MapInfo from '../components/MapInfo'

const locationInfosInitialState = {
  ip: '8.8.8.8',
  location: {
    country: 'US',
    region: 'California',
    city: 'Mountain View',
    lat: 37.40599,
    lng: -122.078514,
    postalCode: '94043',
    timezone: '-07:00',
    geonameId: 5375481,
  },
  domains: [
    '0d2.net',
    '003725.com',
    '0f6.b0094c.cn',
    '007515.com',
    '0guhi.jocose.cn',
  ],
  as: {
    asn: 15169,
    name: 'Google LLC',
    route: '8.8.8.0/24',
    domain: 'https://about.google/intl/en/',
    type: 'Content',
  },
  isp: 'Google LLC',
}

function TrackPage(): ReactElement {
  const [locationInfos, setLocationInfos] = useState(locationInfosInitialState)

  const handleIpChange = async (ipAddress: string): Promise<void> => {
    try {
      const response = await axios.get(
        `https://geo.ipify.org/api/v2/country,city?apiKey=at_IeiFGsDQAQChyx1B0WHnGtjasrkim&ipAddress=${ipAddress}`
      )
      setLocationInfos(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <IpHeader handleIpChange={handleIpChange} />
      <div className="ip-info-wrapper">
        <IpInfo locationInfos={locationInfos} />
      </div>
      <MapInfo
        latitude={locationInfos.location.lat}
        longitude={locationInfos.location.lng}
      />
    </>
  )
}

export default TrackPage
